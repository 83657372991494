let options = {
    root: document.querySelector('[page]'),
    rootMargin: '0px',
    threshold: 0.35
}
let setInview = (entries, observer) => {
    entries.forEach(entry => {
        let rand = Math.random() * (350 - 0) + 0;
        if (entry.isIntersecting) {
            window.setTimeout(() => {
                entry.target.dataset.inview = true;
            }, rand);
            
            observer.unobserve(entry.target);
        }
    });
}
let observer = new IntersectionObserver(setInview, options);
let targets = document.querySelectorAll('[data-inview]');
targets.forEach(target => {
    observer.observe(target);
});