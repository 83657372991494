import './components/in-view-effects.js'

//toggle mobile nav
let mainNav = document.getElementById("js-menu");
let navBarToggle = document.getElementById("js-navbar-toggle");

if(navBarToggle && mainNav){
  navBarToggle.addEventListener("click", function() {
    mainNav.classList.toggle("active");
  }); 
}


//add active class to navigation link of current page
let path = window.location.pathname;
let nav = document.querySelectorAll('.nav-links');

if(nav.length){
  for (let i = 0; i < nav.length; i++) {
    if(nav[i].getAttribute("href") == path) {
      nav[i].classList.add('active');
    }
  }
}